import * as React from 'react'
import { useMediaQuery } from 'react-responsive'

import { Container, Thumbnail, Title, TitleContainer, Summary, PlayIconContainer } from './styles'

type GuideProps = {
    url: string,
    title: React.ReactNode,
    textSummary?: React.ReactNode,
    videoThumbnail: null | {
        url: string,
        alt: string | null,
    },
}

const IMAGE_AR = 720 / 1280
const IMAGE_WIDTH = 100

const playIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 221 221"><g id="uuid-43d4512a-06c9-4bdb-b926-dacc14b065b7"><g><path d="M110.5,18c51,0,92.5,41.5,92.5,92.5s-41.5,92.5-92.5,92.5S18,161.5,18,110.5,59.5,18,110.5,18m0-18C49.5,0,0,49.5,0,110.5s49.5,110.5,110.5,110.5,110.5-49.5,110.5-110.5S171.5,0,110.5,0h0Z" fill="currentColor"></path><polyline points="82.8 59.8 157 110 82.8 160.2" fill="currentColor"></polyline></g></g></svg>
)

export const Guide = ({ url, title, textSummary, videoThumbnail }: GuideProps) => {
    const showThumbnail = useMediaQuery({
        query: '(min-width: 750px)'
    })

    return (
        <Container href={url}>
            {showThumbnail && videoThumbnail && <Thumbnail width={IMAGE_WIDTH} height={IMAGE_WIDTH * IMAGE_AR} src={videoThumbnail.url} alt={videoThumbnail.alt} loading="lazy"/>}
            <TitleContainer><Title>{title}</Title></TitleContainer>
            {textSummary && <Summary>{textSummary}</Summary>}
            <PlayIconContainer>{playIcon}</PlayIconContainer>
        </Container>
    )
}