import { Autocomplete } from './components/Autocomplete'
import { getAlgoliaResults } from '@algolia/autocomplete-js'
import { getSearchClient } from './util/get-search-client'
import { createRoot } from 'react-dom/client'
import { Guide } from './components/Guide'
import { NoResults } from './components/NoResults'
import { Category } from './components/Category'
import { SearchClient } from 'algoliasearch/lite'
import { debouncePromise } from '../util/debounce-promise'
import { getAccessGroupID } from './util/get-access-group-id'
import { getAccessGroupSlug } from './util/get-access-group-slug'

const maybeAddAccessGroupSlug = (url: string) => {
    let result = url

    const accessGroupSlug = getAccessGroupSlug()

    if (accessGroupSlug) {
        result = '/' + accessGroupSlug + url
    }

    return result
}

const getGuideUrl = (slug: string) => maybeAddAccessGroupSlug(`/guides/${slug}`)
const getCategoryUrl = (type: string, slug: string) => {
    const folder = type === 'subcategory' ? 'underkategori' : 'kategori'

    return maybeAddAccessGroupSlug(`/${folder}/${slug}`)
}

const createGetSources = (client: SearchClient) => async ({ query }) => {
    return [
        {
            sourceId: 'guides',
            getItems: async () => {
                return getAlgoliaResults<{ accessGroupID: string, title: string, overrideID?: string, objectID: string }>({
                    searchClient: client,
                    queries: [
                        {
                            indexName: 'guides',
                            query,
                            params: {
                                hitsPerPage: 10,
                                attributesToSnippet: ['textSummary:15'],
                                snippetEllipsisText: '...',
                            },
                        },
                    ],
                    transformResponse({ hits }) {
                        return hits.map(hs => {
                            const hitsRecord: Record<string, typeof hs[number]> = {}

                            for (const hit of hs) {
                                if (!hit.overrideID) {
                                    // This hit does not override anything and there's already something with it's
                                    // ID, so it must have been overwritten by another guide.
                                    if (hitsRecord[hit.objectID]) {
                                        continue
                                    }

                                    hitsRecord[hit.objectID] = hit
                                } else {
                                    hitsRecord[hit.overrideID] = hit
                                }
                            }

                            return Object.values(hitsRecord).slice(0, 5)
                        })
                    }
                })
            },
            templates: {
                header: () => {
                    return <h2 className="aa-SourceHeaderTitle">Guides</h2>
                },
                item: ({ item, components }) => {
                    return <Guide
                        {...item}
                        title={components.Highlight({ hit: item, attribute: 'title' })}
                        textSummary={item.textSummary && components.Snippet({ hit: item, attribute: 'textSummary' })}
                        url={getGuideUrl(item.slug)}
                    />
                },
                noResults: NoResults
            },
            getItemUrl: ({ item }) => getGuideUrl(item.slug)
        },
        {
            sourceId: 'categories',
            getItems: async () => {
                return getAlgoliaResults({
                    searchClient: client,
                    queries: [
                        {
                            indexName: 'categories',
                            query,
                            params: {
                                hitsPerPage: 5,
                            },
                        },
                    ],
                })
            },
            templates: {
                header: () => {
                    return <h2 className="aa-SourceHeaderTitle">Kategorier</h2>
                },
                item: ({ item, components }) => {
                    return <Category
                        url={getCategoryUrl(item.type, item.slug)}
                        title={components.Highlight({ hit: item, attribute: 'title' })}
                    />
                },
                noResults: NoResults
            },
            getItemUrl: ({ item }) => getCategoryUrl(item.type, item.slug)
        }
    ]
}

const render = async () => {
    const rootEl = document.getElementById('search')
    const root = createRoot(rootEl)

    const searchClient = await getSearchClient()

    const getSources = debouncePromise(createGetSources(searchClient), 500)

    root.render(
        <Autocomplete
            openOnFocus
            placeholder="Søg"
            getSources={getSources}
            translations={{
                clearButtonTitle: 'Ryd',
                detachedCancelButtonText: 'Annuller',
                submitButtonTitle: 'Søg'
            }}
        />
    )
}

render()