import styled from '@emotion/styled'

export const Container = styled.a`
    background-color: #f8f9fc;
    border-radius: 8px;
    border: 1px solid #eff2f8;
    max-width: 100%;
    overflow: hidden;
    padding: 16px 12px;
    font-size: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;
    
    &,
    &:hover,
    &:active,
    &:focus,
    &:visited {
        color: #1d1d1d;
        text-decoration: none;
    }
`