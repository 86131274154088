import * as React from 'react'
import { Container } from './styles'

type CategoryProps = {
    url: string,
    title: React.ReactNode,
}

export const Category = ({ url, title }: CategoryProps) => (
    <Container href={url}>
        {title}
    </Container>
)