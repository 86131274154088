import { SEARCH_API_KEY_ENDPOINT } from '../constants'
import algoliasearch from 'algoliasearch/lite'
import { getAccessGroupID } from './get-access-group-id'

export const getSearchClient = async () => {
    const accessGroupID = getAccessGroupID()
    const localSearchCredentialsRaw = window.localStorage.getItem('searchCredentials')
    const localSearchCredentials = localSearchCredentialsRaw ? JSON.parse(localSearchCredentialsRaw) : null

    if (
        localSearchCredentials &&
        localSearchCredentials.appId &&
        localSearchCredentials.key &&
        localSearchCredentials.date &&
        localSearchCredentials.accessGroupID === accessGroupID &&
        // More than 2.5 hours since it was generated.
        (new Date().getTime() - new Date(localSearchCredentials.date).getTime()) / 36e5 <= 2.5
    ) {
        return algoliasearch(localSearchCredentials.appId, localSearchCredentials.key)
    }

    const searchCredentialsResponse = await fetch(SEARCH_API_KEY_ENDPOINT + '?' + new URLSearchParams({
        accessGroupID,
    }))

    if (!searchCredentialsResponse.ok) {
        return null
    }

    const newSearchCredentials = await searchCredentialsResponse.json()
    localStorage.setItem('searchCredentials', JSON.stringify({
        ...newSearchCredentials,
        accessGroupID,
        date: new Date()
    }))

    return algoliasearch(newSearchCredentials.appId, newSearchCredentials.key)
}