import '@algolia/autocomplete-theme-classic'
import { autocomplete } from '@algolia/autocomplete-js'
import * as React from 'react'
import { createElement, Fragment, useEffect, useRef } from 'react'
import { createRoot } from 'react-dom/client'
import './styles.css'

export const Autocomplete = (props) => {
    const containerRef = useRef(null)
    const panelRootRef = useRef(null)
    const rootRef = useRef(null)

    useEffect(() => {
        if (!containerRef.current) {
            return undefined
        }

        const search = autocomplete({
            container: containerRef.current,
            renderer: { createElement, Fragment, render: () => {} },
            render({ children }, root) {
                if (!panelRootRef.current || rootRef.current !== root) {
                    rootRef.current = root

                    panelRootRef.current?.unmount()
                    panelRootRef.current = createRoot(root)
                }

                panelRootRef.current.render(children)
            },
            ...props,
        })

        return () => {
            search.destroy()
        }
    }, [props])

    return <div ref={containerRef}/>
}