import styled from '@emotion/styled'

export const Container = styled.a`
    background-color: #f8f9fc;
    padding-right: 50px;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 8px;
    border: 1px solid #eff2f8;
    position: relative;
    max-width: 100%;
    overflow: hidden;

    &,
    &:hover,
    &:active,
    &:focus,
    &:visited {
        color: #1d1d1d;
        text-decoration: none;
    }
    
    @media (min-width: 750px) {
        display: flex;
        align-items: center;
        padding-left: 4px;
        padding-top: 4px;
        padding-bottom: 4px;
    }
`

export const Thumbnail = styled.img`
    margin-right: 1em;
    border-radius: 4px;
`

export const TitleContainer = styled.div`
    line-height: 1.5em;
    
    @media (min-width: 750px) {
        margin-right: 1em;
        width: 35%;
        flex-shrink: 0;
    }
`

export const Title = styled.h3`
    color: #1d1d1d;
    flex-grow: 1;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`

export const Summary = styled.p`
    flex-grow: 1;
    font-size: 12px;
    color: #999;
    font-weight: 400;
    margin: 0;
    margin-top: 1em;

    @media (min-width: 750px) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 0;
        margin-right: 3%;
    }
`

export const PlayIconContainer = styled.div`
    position: absolute;
    top: 50%;
    right: 1em;
    transform: translateY(-50%);
`